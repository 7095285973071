import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function ShortUrlDetail({ setNotificationMessage, api, updateUsername }) {
  let { code } = useParams();
  const navigate = useNavigate();
  const [shortUrl, setShortUrl] = useState("");

  useEffect(() => {
    api
      .get(`short_url/get/${code}`)
      .then((response) => {
        if (response.data) setShortUrl(response.data);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [code]);

  function confirmDelete() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center">
            <h2>Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <button className="btn btn-danger me-3" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                deleteData();
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  function deleteData(e) {
    api
      .delete(`short_url/delete`, {
        data: { code: code },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Deleted successfully" });
        navigate(`/short-url`);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="mb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/short-url` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" className="mb-3" />
        </Link>
        <br />
        <Link className="text-decoration-none fw-bold pe-2" to={{ pathname: `/short-url/${code}/edit` }}>
          <FontAwesomeIcon icon={faEdit} size="lg" />
        </Link>
        <Link className="text-decoration-none fw-bold" to="#" onClick={confirmDelete}>
          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
        </Link>
      </div>
      <label className="fw-bold">Label</label>
      <p className="ps-3 pt-1">
        {shortUrl ? shortUrl.label.S : <span className="fst-italic text-secondary">Not found</span>}
      </p>
      <label className="fw-bold">Code</label>
      <p className="ps-3 pt-1">
        {shortUrl ? shortUrl.code.S : <span className="fst-italic text-secondary">Not found</span>}
      </p>
      <label className="fw-bold">Link</label>
      <p className="ps-3 pt-1">
        {shortUrl ? shortUrl.link.S : <span className="fst-italic text-secondary">Not found</span>}
      </p>
    </div>
  );
}
