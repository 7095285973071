import { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";

export default function Header({ username, notificationMessage }) {
  useEffect(() => {
    if (notificationMessage?.message) {
      $("#notificationContainer").show().delay(3000).fadeOut(300);
    } else {
      $("#notificationContainer").hide();
    }
  }, [notificationMessage]);

  let loginContainer;
  if (username) {
    loginContainer = (
      <p className="text-end pt-4">
        Welcome, <b className="fs-4">{username}</b>
      </p>
    );
  } else {
    loginContainer = (
      <p className="text-end pt-4 lead">
        <Link className="text-light text-end pt-4" to="/login">
          Login
        </Link>
      </p>
    );
  }

  return (
    <div>
      <div className="border rounded m-1 bg-primary text-light row">
        <Link className="col text-light text-decoration-none" to="/">
          <h1 className="fw-bold p-3 col">DPKhere</h1>
        </Link>
        <div className="col">{loginContainer}</div>
      </div>
      <p
        id="notificationContainer"
        className={`p-2 mx-5 my-2 bg-${notificationMessage?.class} bg-gradient text-center rounded`}
      >
        {notificationMessage?.message}
      </p>
    </div>
  );
}
