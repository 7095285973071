import { Link } from "react-router-dom";

export default function Homepage({ username }) {
  let authenticatedLinks = "";
  if (username) {
    authenticatedLinks = (
      <div>
        <Link className="text-decoration-none" to="/qa">
          <p className="lead fw-bold">Question & Answers</p>
        </Link>
        <Link className="text-decoration-none" to="/short-url">
          <p className="lead fw-bold">Short URL</p>
        </Link>
        <Link className="text-decoration-none" to="/activity">
          <p className="lead fw-bold">Activity</p>
        </Link>
      </div>
    );
  }

  return (
    <div className="rounded border m-1 pt-4 text-center bg-white">
      {authenticatedLinks}
      <Link className="text-decoration-none" to="/sudoku">
        <p className="lead fw-bold">Sudoku</p>
      </Link>
      <Link className="text-decoration-none" to="/match-color">
        <p className="lead fw-bold">Color Matcher</p>
      </Link>
      <Link className="text-decoration-none" to="/photo-editor">
        <p className="lead fw-bold">Photo Editor</p>
      </Link>
    </div>
  );
}
