import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ErrorHandler from "../../helpers/ErrorHandler";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function ShortUrlRedirect({ setNotificationMessage, api, updateUsername }) {
  let { code } = useParams();

  useEffect(() => {
    api
      .get(`short_url/get/${code}`)
      .then((response) => {
        if (response.data) window.location.replace(response.data.link.S);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [code]);

  return <p></p>;
}
