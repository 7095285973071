import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

export default function ShortUrlEdit({ setNotificationMessage, api, updateUsername }) {
  let { code } = useParams();
  const navigate = useNavigate();
  const [label, setLabel] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    api
      .get(`short_url/get/${code}`)
      .then((response) => {
        if (response.data) {
          setLabel(response.data.label.S);
          setLink(response.data.link.S);
        }
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [code]);

  function saveChanges(e) {
    e.preventDefault();
    var postData = {
      code: code,
      label: label,
      link: link,
    };
    api
      .post(`/short_url/update`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Saved successfully" });
        navigate(`/short-url/${code}/detail`);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="pb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/short-url/${code}/detail` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </Link>
      </div>
      <form onSubmit={saveChanges}>
        <div className="mb-3">
          <label htmlFor="short-url-edit-label" className="form-label">
            Label
          </label>
          <input
            id="short-url-edit-label"
            className="form-control"
            type="text"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="short-url-edit-link" className="form-label">
            Link
          </label>
          <input
            id="short-url-edit-link"
            className="form-control"
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
        <input type="submit" className="btn btn-primary" />
      </form>
    </div>
  );
}
