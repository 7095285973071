import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../../helpers/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCopy, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export default function ShortUrlList({ setNotificationMessage, api, updateUsername }) {
  const navigate = useNavigate();
  const [shortUrls, setShortUrls] = useState([]);

  useEffect(() => {
    api
      .get(`short_url/list`)
      .then((response) => {
        if (response.data) setShortUrls(response.data);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
        navigate("/login");
      });
  }, []);

  const renderShortUrls = shortUrls.map((short_url) => {
    return (
      <div className="pb-2" key={short_url.id.S}>
        <Link className="text-decoration-none fw-bold" to={{ pathname: `/short-url/${short_url.code.S}/detail` }}>
          {short_url.label.S}
        </Link>
        <Link target={"_blank"} className="ps-2 float-left" to={{ pathname: `/short-url/${short_url.code.S}` }}>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Link>
        <Link
          className="ps-2 float-left"
          to="/#"
          onClick={(e) => {
            navigator.clipboard.writeText(window.location.origin + `/short-url/${short_url.code.S}`);
            e.preventDefault();
          }}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Link>
      </div>
    );
  });

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div>
        <div className="pb-3">
          <Link className="text-decoration-none bold" to={{ pathname: `/short-url/create` }}>
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          </Link>
        </div>
        <div className="text-center">
          <h3>Short URLs</h3>
        </div>
        <div className="text-center my-3 text-capitalize">{renderShortUrls}</div>
      </div>
    </div>
  );
}
