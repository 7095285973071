import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

export default function ActivityTriggerEdit({ setNotificationMessage, api, updateUsername }) {
  let { code } = useParams();
  const navigate = useNavigate();
  const [triggerName, setTriggerName] = useState(null);

  useEffect(() => {
    api
      .get(`activity/trigger/get/${code}`)
      .then((response) => {
        if (response.data) {
          setTriggerName(response.data.trigger_name.S);
        }
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [code]);

  function saveChanges(e) {
    e.preventDefault();
    var postData = {
      code: code,
      trigger_name: triggerName,
    };
    api
      .post(`/activity/trigger/update`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Saved successfully" });
        navigate(`/activity/trigger`);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="pb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/activity/trigger` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </Link>
      </div>
      <form onSubmit={saveChanges}>
        <div className="mb-3">
          <label htmlFor="trigger-edit-name" className="form-label">
            Label
          </label>
          <input
            id="trigger-edit-name"
            className="form-control"
            type="text"
            value={triggerName}
            onChange={(e) => setTriggerName(e.target.value)}
          />
        </div>
        <input type="submit" className="btn btn-primary" />
      </form>
    </div>
  );
}
