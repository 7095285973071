import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../../helpers/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function ActivityList({ setNotificationMessage, api, updateUsername }) {
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    updateActivities();
  }, []);

  function updateActivities() {
    api
      .get(`activity/list`)
      .then((response) => {
        if (response.data) setActivities(response.data);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        navigate("/login");
      });
  }

  function confirmDelete(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center">
            <h2>Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <button className="btn btn-danger me-3" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                deleteData(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  function deleteData(created_at) {
    api
      .delete(`activity/delete`, {
        data: { created_at: created_at },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Deleted successfully" });
        updateActivities();
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
      });
  }

  const renderActivities = activities.map((activity, index) => {
    let date = new Date(parseInt(activity.created_at.S));
    return (
      <tr key={activity.created_at.S}>
        <td>{index + 1}</td>
        <td>{activity.activity.S}</td>
        <td>
          {date.toLocaleDateString("en-CA")}&nbsp;
          {date.toLocaleTimeString("en-US")}
        </td>
        <td>
          <Link
            className="text-decoration-none fw-bold ps-2"
            to="#"
            onClick={() => confirmDelete(activity.created_at.S)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </td>
      </tr>
    );
  });

  let renderResult = (
    <table className="table table-striped table-hover border">
      <thead>
        <tr>
          <th>#</th>
          <th>Activity</th>
          <th>Created At</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{renderActivities}</tbody>
    </table>
  );

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="text-center">
        <h3>Activity</h3>
        <div className="text-end">
          <Link className="text-decoration-none fw-bold" to="/activity/trigger">
            <button className="btn btn-primary me-1">Triggers</button>
          </Link>
        </div>
      </div>
      <div className="text-center my-3">{renderResult}</div>
    </div>
  );
}
