import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Login({ setNotificationMessage, api, refreshAccessToken }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function login(e) {
    e.preventDefault();
    if (username === "" || password === "") {
      setNotificationMessage({ class: "warning", message: "All fields are mandatory" });
      return;
    }

    const jsonReq = { username: username, password: password };
    api
      .post(`user/login`, jsonReq)
      .then((response) => {
        Cookies.set("access_token", response.data.access_token.S);

        setUsername("");
        setPassword("");
        setNotificationMessage({ class: "success", message: "Logged in" });
        refreshAccessToken(response.data.access_token.S, response.data.full_name.S);
        navigate("/");
      })
      .catch((error) => {
        setNotificationMessage({ class: "danger", message: "Wrong Username or Password" });
        setPassword("");
      });
  }

  return (
    <div className="p-5 m-1 border rounded bg-white">
      <h2 className="text-center mb-5">Login</h2>
      <form onSubmit={login} style={{ width: "250px" }} className="m-auto">
        <div className="mb-3">
          <label className="form-label" htmlFor="login-username">
            <b>Username</b>
          </label>
          <input
            className="form-control"
            type="text"
            id="login-username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="login-password">
            <b>Password</b>
          </label>
          <input
            className="form-control"
            type="password"
            id="login-password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            autoComplete="on"
          />
        </div>
        <div className="mb-3 text-center">
          <button className="btn btn-primary ">Login</button>
        </div>
      </form>
    </div>
  );
}
